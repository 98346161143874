import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Loader } from '../Loader/Loader';
import { TextOpacity } from '../TextOpacity/TextOpacity';
import styles from './PrintPage.module.css';
export function PrintPage(props) {
    switch (props.variant) {
        case 'loading': {
            return (_jsxs("div", Object.assign({ className: styles['loader'] }, { children: [_jsx(Loader, {}), props.loadingValue !== undefined && props.loadingValue > 0 && (_jsx(TextOpacity, { opacity: 0.6, text: `${Math.round(props.loadingValue * 100)}%` }))] })));
        }
        case 'condition': {
            return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "condition-print-modal" }, { children: [_jsxs("div", Object.assign({ className: styles['main'] }, { children: [props.header, _jsx("div", Object.assign({ className: styles['photo'] }, { children: props.photo })), _jsx("div", Object.assign({ className: styles['map'] }, { children: props.map }))] })), _jsx("div", Object.assign({ className: styles['sidebar'] }, { children: props.sidebar }))] })));
        }
        case 'sign': {
            return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['main'] }, { children: [props.header, _jsx("div", Object.assign({ className: styles['photo'] }, { children: props.photo })), _jsx("div", Object.assign({ className: styles['map'] }, { children: props.map }))] })), _jsx("div", Object.assign({ className: styles['sidebar-sign'] }, { children: props.sidebar }))] })));
        }
        case 'sign-inventory': {
            return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['main'] }, { children: [props.header, _jsx("div", Object.assign({ className: styles['photo'] }, { children: props.photo })), _jsx("div", Object.assign({ className: styles['map'] }, { children: props.map }))] })), _jsx("div", Object.assign({ className: styles['sidebar-sign'] }, { children: props.sidebar }))] })));
        }
        case 'plan': {
            return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsxs("div", Object.assign({ className: styles['main'] }, { children: [props.header, _jsx("div", Object.assign({ className: styles['map'] }, { children: props.map }))] })), _jsx("div", Object.assign({ className: styles['sidebar'] }, { children: props.sidebar }))] })));
        }
        default:
            return _jsx(_Fragment, {});
    }
}
