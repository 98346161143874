import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useMatch } from '@tanstack/react-location';
import { LAYER_PATH } from '../../common/layer';
import { uniqueV2 } from '../../common/utils/array';
import { PrintAssetContainer } from '../../containers/PrintAssetContainer/PrintAssetContainer';
import { PrintContainer } from '../../containers/PrintContainer';
import { PrintMarkerContainer } from '../../containers/PrintMarkerContainer/PrintMarkerContainer';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchTeamAccount } from '../../state/slices/auth';
import { fetchPrintData } from '../../state/slices/print';
import { fetchSignInventoryTree } from '../../state/slices/shared';
export function AppPrint() {
    const { params: { featureId, printType } } = useMatch();
    const dispatch = useAppDispatch();
    const { teamAccount } = useAppSelector((state) => state.auth);
    useEffect(() => {
        dispatch(fetchTeamAccount());
    }, [dispatch]);
    useEffect(() => {
        if (teamAccount) {
            switch (printType) {
                case 'segment':
                case 'sign':
                case 'plan': {
                    const featureIds = featureId.split(',').map((id) => parseInt(id));
                    dispatch(fetchPrintData({
                        type: printType,
                        ids: featureIds
                    }));
                    break;
                }
                case 'sign-inventory': {
                    const featureIds = featureId.split(',').map((id) => parseInt(id));
                    dispatch(fetchSignInventoryTree());
                    dispatch(fetchPrintData({
                        type: printType,
                        ids: featureIds
                    }));
                    break;
                }
                case 'point': {
                    const featureIds = featureId.split(',');
                    dispatch(fetchPrintData({
                        type: printType,
                        uuids: featureIds
                    }));
                    break;
                }
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamAccount]);
    if (printType === LAYER_PATH.marker) {
        return (_jsx(PrintMarkerContainer, { ids: uniqueV2(featureId.split(',').map((id) => parseInt(id))) }));
    }
    if (printType === LAYER_PATH.asset) {
        return _jsx(PrintAssetContainer, { ids: featureId.split(',').map((id) => parseInt(id)) });
    }
    return _jsx(PrintContainer, {});
}
