import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { MARKER_STATUS_ID, TASKS_TAB_INDEX } from '../../common/consts/marker';
import { MarkerStatusIcon } from '../../common/convert';
import { formatLatLng } from '../../common/geoFormatter';
import { useCurrentTabScrollTop } from '../../hooks/useCurrentTabScrollTop';
import { Button } from '../../uikit/Button/Button';
import { NotificationBadge } from '../../uikit/NotificationBadge/NotificationBadge';
import { TabBar } from '../../uikit/TabBar/TabBar';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';
import { CostsSectionTeaser } from '../CostsSection/CostsSectionTeaser';
import { DetailsMetaInformation } from '../DetailsPanel/DetailsMetaInformation/DetailsMetaInformation';
import { DetailsMetaInformationItem } from '../DetailsPanel/DetailsMetaInformation/DetailsMetaInformationItem/DetailsMetaInformationItem';
import { CalendarBlank, MapPin } from '../icons';
import { Image } from '../ImagePreview/Image/Image';
import { ImagePreview } from '../ImagePreview/ImagePreview';
import { TypedDitto } from '../TypedDitto';
import styles from './MarkerDetail.module.css';
const MARKER_IMAGE_HEIGHT = 250;
const MARKER_IMAGE_WIDTH = 356;
export function MarkerDetail({ isFormLoading, marker, header, markerLowerForm, markerChangelog, assetSection, onSetStatusDone, onImageUploadClick, onImageEnlargeClick, minimap, assetCount, filesSection, filesCount, costsSection, currentTab, setCurrentTab }) {
    var _a;
    const refContent = React.createRef();
    useCurrentTabScrollTop({ refContent, currentTab });
    function handleDoneClick() {
        onSetStatusDone();
    }
    const pinLabel = formatLatLng({
        lat: marker.point.latitude,
        lng: marker.point.longitude
    }, {
        // In US locale, 5 decimal places still
        // fits with the date & time in one line.
        // Corresponds to 1.11 m precision at equator.
        // Source: https://en.wikipedia.org/wiki/Decimal_degrees
        precision: 5
    });
    const tabs = [
        {
            label: _jsx(Ditto, { componentId: "details" }),
            content: (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles['image-preview'] }, { children: minimap ? (minimap) : (_jsx(ImagePreview, Object.assign({ width: MARKER_IMAGE_WIDTH, height: MARKER_IMAGE_HEIGHT, onClickEnlarge: onImageEnlargeClick, uploadImage: _jsx(TypedDitto, { componentId: "uploadimage" }), onClickUpload: onImageUploadClick }, { children: marker.photos.map((img, i) => (_jsx(Image, { src: img.thumbUrl, alt: "marker photo" }, i))) }))) })), _jsx("div", Object.assign({ className: styles['meta-info'] }, { children: _jsxs(DetailsMetaInformation, { children: [_jsx(DetailsMetaInformationItem, { icon: _jsx(CalendarBlank, {}), label: marker.createdAt.dateTime }), _jsx(DetailsMetaInformationItem, { icon: _jsx(MapPin, {}), label: pinLabel, "data-test": "task-coords" })] }) })), ((_a = marker.audio) === null || _a === void 0 ? void 0 : _a.uri) && (_jsx("div", Object.assign({ className: styles['audio-player'] }, { children: _jsx(AudioPlayer, { src: marker.audio.uri, maxLength: marker.audio.duration }) }))), _jsx("div", Object.assign({ className: styles['content-group'] }, { children: markerLowerForm }))] }))
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "inventory.inventory" }), _jsx(NotificationBadge, { content: assetCount })] })),
            content: (_jsx("div", Object.assign({ className: classes(styles['linked-asset'], assetCount > 0 && styles['linked-asset-content']) }, { children: assetSection })))
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "files.files" }), _jsx(NotificationBadge, { content: filesCount })] })),
            content: _jsx("div", Object.assign({ className: styles['files'] }, { children: filesSection }))
        },
        {
            label: _jsx(Ditto, { componentId: "activity" }),
            content: _jsx("div", Object.assign({ className: styles['changelog-container'] }, { children: markerChangelog }))
        }
    ];
    if (costsSection) {
        tabs.splice(TASKS_TAB_INDEX.costs, 0, {
            label: (_jsxs(_Fragment, { children: [_jsx(Ditto, { componentId: "tasks.costs.costs" }), costsSection.type === CostsSectionTeaser ? (_jsx(NotificationBadge, { variant: "dark", content: _jsx(Ditto, { componentId: "soon" }), fontSize: 12 })) : (_jsx(NotificationBadge, { content: _jsx(Ditto, { componentId: "betalabel" }), fontSize: 12 }))] })),
            content: _jsx("div", Object.assign({ className: styles['costs'] }, { children: costsSection }))
        });
    }
    const content = tabs[currentTab].content;
    return (_jsxs("div", Object.assign({ className: styles['container'], "data-test": "task-details" }, { children: [header, _jsx(TabBar, { tabs: tabs, current: currentTab, setCurrent: setCurrentTab }), _jsx("div", Object.assign({ ref: refContent, className: styles['content-group'] }, { children: content })), marker.statusId !== MARKER_STATUS_ID.closed &&
                currentTab === TASKS_TAB_INDEX.details && (_jsx("div", Object.assign({ className: styles['button-group'] }, { children: _jsx(Button, Object.assign({ disabled: isFormLoading, color: "dark", variant: "fullwidth", size: "medium", onClick: handleDoneClick, icon: _jsx(MarkerStatusIcon, { value: MARKER_STATUS_ID.closed }) }, { children: _jsx(Ditto, { componentId: "tasks.setstatustoclosed" }) })) })))] })));
}
