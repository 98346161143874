import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Navigate, Outlet, ReactLocation, Router } from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import { parseSearch, ROOT_PATH, stringifySearch } from '../../common/url';
import config from '../../config/config.json';
import { ErrorPageContainer } from '../../containers/ErrorPageContainer/ErrorPageContainer';
import { JoinContainer } from '../../containers/JoinContainer/JoinContainer';
import { MissingUserDetailContainer } from '../../containers/MissingUserDetailContainer/MissingUserDetailContainer';
import { RegisterContainer } from '../../containers/RegisterContainer/RegisterContainer';
import { Account } from '../Account/Account';
import { AppContainer } from '../AppContainer/AppContainer';
import { AppPrint } from '../AppPrint';
import { Auth } from '../Auth/Auth';
import { CloseMarkerPage } from '../CloseMarkerPage/CloseMarkerPage';
import { ProductFruitsWrapper } from '../ProductFruitsWrapper/ProductFruitsWrapper';
import { RedirectSettings } from '../RedirectSettings/RedirectSettings';
import { RedirectUniversalLink } from '../RedirectUniversalLink/RedirectUniversalLink';
const location = new ReactLocation({
    parseSearch: parseSearch,
    stringifySearch: stringifySearch
});
export function AppRouter() {
    return (_jsxs(Router, Object.assign({ location: location, routes: [
            {
                path: '/',
                element: _jsx(Navigate, { to: `/${ROOT_PATH.main}`, replace: true })
            },
            {
                path: ROOT_PATH.closeMarker,
                element: _jsx(CloseMarkerPage, {})
            },
            {
                path: ROOT_PATH.account,
                element: _jsx(Auth, { checkTargetSchema: false }),
                children: [
                    {
                        path: '/',
                        element: _jsx(Account, {})
                    }
                ]
            },
            {
                path: ROOT_PATH.main,
                element: _jsx(Auth, {}),
                children: [
                    {
                        path: '/',
                        element: _jsx(AppContainer, {})
                    },
                    {
                        path: ':mapLayerId',
                        element: _jsx(AppContainer, {})
                    }
                ]
            },
            {
                path: ROOT_PATH.universalLink,
                element: _jsx(Auth, {}),
                children: [
                    {
                        path: '/:universalLinkType/:universalLinkId',
                        element: _jsx(RedirectUniversalLink, {}),
                        children: [
                            {
                                path: '/',
                                element: _jsx(AppContainer, {})
                            }
                        ]
                    },
                    {
                        element: _jsx(ErrorPageContainer, { variant: "not-found" })
                    }
                ]
            },
            {
                path: ROOT_PATH.settings,
                element: _jsx(Auth, {}),
                children: [
                    {
                        path: '/:settingsPageId',
                        element: _jsx(RedirectSettings, {}),
                        children: [
                            {
                                path: '/',
                                element: _jsx(AppContainer, {})
                            }
                        ]
                    },
                    {
                        element: _jsx(ErrorPageContainer, { variant: "not-found" })
                    }
                ]
            },
            {
                path: ROOT_PATH.register,
                element: _jsx(RegisterContainer, {})
            },
            {
                path: ROOT_PATH.update,
                element: _jsx(MissingUserDetailContainer, {})
            },
            {
                path: ROOT_PATH.join,
                children: [
                    {
                        path: '/:token',
                        element: _jsx(JoinContainer, {})
                    }
                ]
            },
            {
                path: ROOT_PATH.print,
                element: _jsx(Auth, {}),
                children: [
                    {
                        path: '/:printType/:featureId',
                        element: _jsx(AppPrint, {})
                    },
                    {
                        element: _jsx(ErrorPageContainer, { variant: "not-found" })
                    }
                ]
            },
            {
                path: ROOT_PATH.unauthorized,
                element: _jsx(ErrorPageContainer, { variant: "no-permission" })
            },
            {
                // Passing no route is equivalent to passing `path: '*'`
                element: _jsx(ErrorPageContainer, { variant: "not-found" })
            }
        ] }, { children: [_jsx(Outlet, {}), config.reactLocationDevtools && (_jsx(ReactLocationDevtools, { initialIsOpen: false, position: "bottom-right" })), _jsx(ProductFruitsWrapper, {})] })));
}
