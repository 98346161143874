import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { motion } from 'framer-motion';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { IconText } from '../../../IconText/IconText';
import { SectionExpander } from '../SectionExpander/SectionExpander';
import styles from './SectionLayer.module.css';
export function SectionLayer({ headerIcon, headerTitle, children, onHeaderClick, collapsed, collapsible }) {
    const height = collapsed ? 0 : 'auto';
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], collapsed && styles['container--collapsed']) }, { children: [_jsx("div", Object.assign({ className: classes(styles['header'], collapsible && styles['header--collapsible']), onClick: () => collapsible && onHeaderClick && onHeaderClick() }, { children: _jsx(IconText, { icon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: headerIcon })), text: headerTitle, trailingElement: _jsx(SectionExpander, { collapsed: !!collapsed, disabled: !collapsible }) }) })), _jsx(motion.div, Object.assign({ animate: { height: height }, transition: transition, className: styles['children'] }, { children: children }))] })));
}
const transition = { type: 'spring', stiffness: 500, damping: 60 };
