import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { useDittoWrapper } from '../../../../hooks/useDittoWrapper';
import { Button } from '../../../../uikit/Button/Button';
import { Divider } from '../../../../uikit/Divider/Divider';
import { Grabber } from '../../../../uikit/Grabber/Grabber';
import { ActionButton } from '../../../ActionButton/ActionButton';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { Bin, External, Reset } from '../../../icons';
import { InfoTooltip } from '../../../InfoTooltip/InfoTooltip';
import styles from './PlanTreatmentsSettingRow.module.css';
export const PlanTreatmentsSettingRow = forwardRef(function PlanTreatmentsSettingRow({ className, isDefault, title, description, checkbox, autoFocus, unitSelect, costsInput, lifeExtensionInput, categorySelect, onReset, onDelete }, ref) {
    const [isRowHovering, setIsRowHovering] = useState(false);
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const wikiLink = useDittoWrapper({ componentId: 'wikilink.treatmentscostslifeextension' });
    // TODO: remove if not needed
    useImperativeHandle(ref, () => ({
        wrapperRef: wrapperRef.current,
        inputRef: inputRef.current
    }));
    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.select();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx("div", Object.assign({ ref: wrapperRef, className: styles['wrapper'] }, { children: _jsxs("div", Object.assign({ className: classes(styles['container'], isRowHovering && styles['container__hover'], className), onMouseEnter: () => setIsRowHovering(true), onMouseLeave: () => setIsRowHovering(false) }, { children: [_jsx("div", Object.assign({ className: styles['drag-control-placeholder'] }, { children: _jsx(Grabber, { variant: "solid" }) })), _jsxs("div", Object.assign({ className: classes(styles['box'], !isDefault && styles['box--editable']) }, { children: [_jsxs("div", Object.assign({ className: styles['title-section'] }, { children: [_jsxs("div", Object.assign({ className: styles['title-wrapper'] }, { children: [isDefault ? (_jsxs("div", Object.assign({ className: styles['title'] }, { children: [title, _jsx(InfoTooltip, { tooltip: _jsxs(_Fragment, { children: [_jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(External, {}) })), _jsx("span", Object.assign({ className: styles['link'] }, { children: _jsx(Ditto, { componentId: "infobutton.learnmore" }) }))] }), onClick: () => window.open(wikiLink, '_blank') })] }))) : (title), isDefault ? (_jsx("div", Object.assign({ className: styles['description'] }, { children: description }))) : (_jsxs(_Fragment, { children: [_jsx(Divider, { className: styles['input-divider'], margin: "0" }), description] }))] })), _jsx("div", Object.assign({ className: styles['reset-button-wrapper'] }, { children: isDefault && (_jsx(ActionButton, { disabled: !onReset, onClick: onReset, icon: _jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(Reset, {}) })), tooltip: {
                                            text: onReset ? (_jsx(Ditto, { componentId: "tooltip.treatments.resettodefault" })) : (_jsx(Ditto, { componentId: "tooltip.treatments.defaultvalueapplied" }))
                                        }, size: "small" })) }))] })), _jsxs("div", Object.assign({ className: styles['cost-section'] }, { children: [costsInput, _jsx(Divider, { className: styles['input-divider'], width: "1px", height: "48px", margin: "0" }), unitSelect] })), _jsx("div", Object.assign({ className: styles['life-extension-section'] }, { children: lifeExtensionInput })), _jsx("div", Object.assign({ className: styles['life-category'] }, { children: categorySelect }))] })), isDefault
                    ? checkbox && _jsx("div", Object.assign({ className: styles['checkbox'] }, { children: checkbox }))
                    : onDelete &&
                        isRowHovering && (_jsx(Button, { className: styles['icon-delete'], icon: _jsx(Bin, {}), onClick: onDelete }))] })) })));
});
