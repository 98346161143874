import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { isOverdue } from '../../../common/recurringRules';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { RepeatBadge } from '../../DatePickerContent/RepeatBadge/RepeatBadge';
import { SelectedFrequency } from '../../DatePickerContent/RepeatForm/SelectedFrequency';
import { FormInputCurrentSelectedValue } from '../../FormInputSelect/FormInputSelect';
import { CalendarDueDate, Caret } from '../../icons';
import { TypedDitto } from '../../TypedDitto';
import styles from './DueDatePickerControl.module.css';
export function DueDatePickerControl({ value, variant, disabled, dateTimeFormatter, onClick, frequency }) {
    const placeholderLabel = useDittoWrapper({ componentId: 'notspecified' });
    return (_jsxs("div", Object.assign({ className: classes(styles['field'], styles[`field__${variant}`], disabled && styles['disabled']), style: { width: '100%' }, tabIndex: 0, "data-test": "due-date-select", onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick() }, { children: [_jsxs("div", Object.assign({ className: styles['label-container'] }, { children: [_jsx("div", Object.assign({ className: classes(styles['label'], value && variant !== 'ghost' && styles['label__small']) }, { children: _jsx("span", Object.assign({ className: styles['label-text'] }, { children: _jsx(TypedDitto, { componentId: "duedate" }) })) })), _jsx("div", Object.assign({ className: classes(value && isOverdue(value) && styles['overdue']) }, { children: value ? (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(CalendarDueDate, {}), text: _jsxs(_Fragment, { children: [dateTimeFormatter(value).date, _jsx("span", { children: frequency && frequency.repeat !== 'never' && (_jsx(RepeatBadge, { text: _jsx(SelectedFrequency, { frequency: frequency }) })) })] }), bold: true })) : (_jsx(FormInputCurrentSelectedValue, { icon: _jsx(CalendarDueDate, {}), text: _jsx("span", Object.assign({ className: styles['placeholder'] }, { children: placeholderLabel })) })) }))] })), _jsx("span", Object.assign({ className: styles['trailing-icon'] }, { children: _jsx(Caret, {}) }))] })));
}
