import { parseSearchWith, stringifySearchWith } from '@tanstack/react-location';
import configFile from '../config/config.json';
import { LAYER_PATH } from './layer';
export const ROOT_PATH = {
    main: 'main',
    print: 'print',
    // open in mobile app if the app is installed, if not open in web app
    // format, for example: /universal-link/marker/693819
    universalLink: 'universal-link',
    settings: 'settings',
    // no login page needed, it just redirects
    // login: "login",
    join: 'join',
    register: 'register',
    account: 'account',
    update: 'update',
    unauthorized: 'unauthorized',
    closeMarker: 'close-marker'
};
// https://react-location.tanstack.com/guides/custom-search-param-serialization#using-base64
export const parseSearch = parseSearchWith((value) => {
    const result = JSON.parse(decodeFromURL(value));
    return result;
});
// https://react-location.tanstack.com/guides/custom-search-param-serialization#using-base64
export const stringifySearch = stringifySearchWith((value) => {
    return encodeToURL(JSON.stringify(value));
});
// https://react-location.tanstack.com/guides/custom-search-param-serialization#using-base64
function decodeFromURL(str) {
    return decodeURIComponent(Array.prototype.map
        .call(atob(str), function (c) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
}
// https://react-location.tanstack.com/guides/custom-search-param-serialization#using-base64
function encodeToURL(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }));
}
export function getURLSearchParams() {
    const searchParams = parseSearch(window.location.search);
    return searchParams;
}
/**
 * @deprecated
 */
// Parse .../close-point-of-interest?pointOfInterestId={id}&token={token}&language={locale}&address={address}
export function getCloseTaskURLData() {
    var _a;
    const params = new URLSearchParams(window.location.search);
    return {
        id: params.get('pointOfInterestId') !== null
            ? parseInt((_a = params.get('pointOfInterestId')) !== null && _a !== void 0 ? _a : '', 10)
            : null,
        token: params.get('token'),
        locale: params.get('language'),
        address: params.get('address')
    };
}
// Parse ?token={token}&locale={locale}
export function getCloseMarkerURLData() {
    const params = new URLSearchParams(window.location.search);
    return {
        token: params.get('token'),
        locale: params.get('locale')
    };
}
export function getURLMapLayerId() {
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    if (parts.length === 0) {
        return undefined;
    }
    const LAYER_PART_INDEX = 2;
    const layerPart = parts[LAYER_PART_INDEX];
    if (Object.values(LAYER_PATH).includes(layerPart)) {
        return layerPart;
    }
}
const API_SUFFIX = '/api';
const GATEWAY_SUFFIX = '/gw';
function parseConfigApiUrl() {
    return configFile.api.url === API_SUFFIX
        ? `${window.location.origin}${API_SUFFIX}`
        : configFile.api.url;
}
function parseConfigGatewayUrl() {
    return configFile.gw.url === GATEWAY_SUFFIX
        ? `${window.location.origin}${GATEWAY_SUFFIX}`
        : configFile.gw.url;
}
export function getApiUrl(useGateway) {
    if (useGateway) {
        return parseConfigGatewayUrl();
    }
    return parseConfigApiUrl();
}
export function getApiHost() {
    return parseConfigApiUrl().replace(API_SUFFIX, '');
}
