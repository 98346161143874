import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import { DEFAULT_ZOOM, SIGN_INVENTORY_LAYER_ID } from '../../common/mapbox';
import styles from './TrafficSignRotator.module.css';
export const TrafficSignRotator = ({ orientation, isRotatable, onOrientationChange, variant }) => {
    const { current: map } = useMap();
    const [currentZoom, setCurrentZoom] = useState((map === null || map === void 0 ? void 0 : map.getZoom) || DEFAULT_ZOOM);
    const [relativeOrientation, setRelativeOrientation] = useState(orientation);
    const [isVisible, setIsVisible] = useState(isRotatable || variant === 'create');
    const [isDragging, setIsDragging] = useState(false);
    const imgRef = useRef(null);
    const handleRef = useRef(null);
    const fullCircle = 360;
    const halfCircle = 180;
    useEffect(() => {
        if (!map)
            return;
        map.on('zoom', () => {
            setCurrentZoom(map.getZoom());
        });
        const handleRotate = () => {
            const mapBearing = map.getBearing();
            setRelativeOrientation(orientation - mapBearing);
        };
        handleRotate();
        map.on('rotate', handleRotate);
        const handleRender = () => {
            const signs = map.queryRenderedFeatures(undefined, {
                layers: [SIGN_INVENTORY_LAYER_ID.selected]
            });
            if (!signs || !signs.length) {
                setIsVisible(false);
                return;
            }
            setIsVisible(true);
        };
        if (variant === 'edit') {
            map.on('render', handleRender);
        }
        return () => {
            map.off('rotate', handleRotate);
            if (variant === 'edit')
                map.off('render', handleRender);
        };
    }, [map, orientation, variant]);
    function handleMouseUp() {
        if (!map || !isDragging) {
            return;
        }
        map.getMap().dragPan.enable();
        setIsDragging(false);
        onOrientationChange === null || onOrientationChange === void 0 ? void 0 : onOrientationChange(relativeOrientation);
    }
    useEffect(() => {
        function handleMouseMove(e) {
            if (isDragging && imgRef.current) {
                const imgRect = imgRef.current.getBoundingClientRect();
                const centerX = imgRect.left + imgRect.width / 2;
                const centerY = imgRect.top + imgRect.height / 2;
                const deltaX = e.clientX - centerX;
                const deltaY = e.clientY - centerY;
                let angle = Math.atan2(deltaY, deltaX) * (halfCircle / Math.PI);
                angle = (angle + fullCircle + 90) % fullCircle;
                setRelativeOrientation(angle);
            }
        }
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging, map, relativeOrientation]);
    function handleMouseDown() {
        if (!map) {
            return;
        }
        map.getMap().dragPan.disable();
        setIsDragging(true);
    }
    return (_jsx("div", Object.assign({ ref: imgRef, className: styles['container'], style: {
            transform: `rotate(${relativeOrientation}deg)`,
            transformOrigin: 'center center',
            display: isVisible ? 'flex' : 'none'
        } }, { children: currentZoom > DEFAULT_ZOOM && (_jsxs(_Fragment, { children: [_jsx("img", { className: styles['sign-orientation'], src: isRotatable ? '/sign-orientation-selected.png' : '/sign-orientation.png' }), isRotatable && (_jsx("div", { ref: handleRef, className: styles['rotation-handle'], onMouseDown: handleMouseDown, onMouseUp: handleMouseUp }))] })) })));
};
