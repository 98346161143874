import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Ditto } from 'ditto-react';
import { TreatmentIcon } from '../../../../common/icon';
import { FormInputCurrentSelectedValue, FormInputSelect } from '../../../FormInputSelect/FormInputSelect';
import { TextOverflow } from '../../../TextOverflow/TextOverflow';
export function TreatmentSelectField({ width = 332, options, value, variant, disabled, onClick }) {
    const treatment = useMemo(() => {
        if (!value) {
            return undefined;
        }
        return options.find((treatment) => treatment.id === value);
    }, [options, value]);
    return (_jsx("div", Object.assign({ style: { width }, onClick: () => !disabled && (onClick === null || onClick === void 0 ? void 0 : onClick()), onKeyDown: (event) => {
            if (event.key === 'Enter') {
                !disabled && (onClick === null || onClick === void 0 ? void 0 : onClick());
            }
        } }, { children: _jsx(FormInputSelect, { width: width, label: _jsx(Ditto, { componentId: "filter.measure" }), value: _jsx(TreatmentSelectItemCurrentSelectedValue, { title: treatment === null || treatment === void 0 ? void 0 : treatment.title, categoryTypeId: treatment === null || treatment === void 0 ? void 0 : treatment.categoryTypeId }), "data-test": "segment-form-select-measure", variant: variant, hideMenu: true }) })));
}
export function TreatmentSelectItemCurrentSelectedValue({ title, categoryTypeId, tooltipPosition = 'up' }) {
    const notspecified = _jsx(Ditto, { componentId: "notspecified" });
    return (_jsx(FormInputCurrentSelectedValue, { empty: !title, icon: title ? TreatmentIcon(categoryTypeId) : undefined, text: title ? (_jsx(TextOverflow, { text: title, maxLines: 1, tooltip: "small", tooltipPosition: tooltipPosition, tooltipMaxWidth: 170 })) : (notspecified) }));
}
