import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Ditto } from 'ditto-react';
import { getFilterLabel } from '../../common/conditionFilterTypes';
import { useDittoWrapper } from '../../hooks/useDittoWrapper';
import { Divider } from '../../uikit/Divider/Divider';
import { DamageGrade } from '../DamageGrade/DamageGrade';
import { AverageGrading } from '../SegmentDetails/AverageGrading/AverageGrading';
import { AverageGradingItem } from '../SegmentDetails/AverageGrading/AverageGradingItem/AverageGradingItem';
import styles from './DamageGradingPanel.module.css';
export function DamageGradingPanel({ countryCode, overallGrade, damageStats, roadDamageTypes, roadDamageGroups, showGradePCI, showGradePaser, variant = 'default' }) {
    const isDamagesEmpty = !damageStats || Object.keys(damageStats).length === 0;
    const isOpenDefaultValue = variant === 'print';
    const evennessWikiUrl = useDittoWrapper({ componentId: 'wikilink.evenness' });
    const buildDamageGroups = useCallback(() => {
        return roadDamageGroups
            .filter((group) => group.name !== 'unevenness')
            .map((g) => {
            const children = g.childrens.map((name) => ({
                key: name,
                title: getFilterLabel(roadDamageTypes, name),
                grade: (damageStats !== null && damageStats !== void 0 ? damageStats : {})[name]
            }));
            return {
                key: g.name,
                title: g.label,
                grade: children.reduce((prev, current) => (current.grade > prev ? current.grade : prev), 1),
                isOpen: isOpenDefaultValue,
                children
            };
        });
    }, [damageStats, isOpenDefaultValue, roadDamageGroups, roadDamageTypes]);
    const evenness = useMemo(() => {
        const data = roadDamageGroups.find((group) => group.name === 'unevenness');
        if (data) {
            return {
                key: data.name,
                title: getFilterLabel(roadDamageTypes, data.name),
                grade: (damageStats !== null && damageStats !== void 0 ? damageStats : {})[data.name]
            };
        }
    }, [damageStats, roadDamageGroups, roadDamageTypes]);
    useEffect(() => {
        setDamageGroups(buildDamageGroups());
    }, [buildDamageGroups, damageStats]);
    const [damageGroups, setDamageGroups] = useState(isDamagesEmpty ? [] : buildDamageGroups());
    const isAllOpen = damageGroups.every((group) => group.isOpen);
    const handleToggle = (key) => {
        setDamageGroups(damageGroups.map((group) => key === group.key ? Object.assign(Object.assign({}, group), { isOpen: !group.isOpen }) : group));
    };
    const handleToggleAll = () => {
        const isOpen = !isAllOpen;
        setDamageGroups(damageGroups.map((group) => (Object.assign(Object.assign({}, group), { isOpen }))));
    };
    return (_jsxs(AverageGrading, Object.assign({ info: isDamagesEmpty ? _jsx(Ditto, { componentId: "info.cobblestonegrading" }) : undefined, headline: _jsx(Ditto, { componentId: "damagegrading" }), averageGrading: overallGrade !== null && overallGrade !== void 0 ? overallGrade : 0, averageGradingPrefix: countryCode === 'US' ? (_jsx(Ditto, { componentId: "grading.emi" })) : (_jsx(Ditto, { componentId: "grade.totalamount" })), averageGradingPCI: showGradePCI ? damageStats === null || damageStats === void 0 ? void 0 : damageStats.pci : undefined, averageGradingPaser: showGradePaser ? damageStats === null || damageStats === void 0 ? void 0 : damageStats.paser : undefined }, { children: [!isDamagesEmpty &&
                damageGroups.map((g) => (_jsx(AverageGradingItem, Object.assign({ title: g.title, grade: g.grade, isOpen: g.isOpen, onToggle: () => handleToggle(g.key), variant: variant }, { children: g.children.map((i) => (_jsx(DamageGrade, { title: i.title, grade: i.grade, className: styles['damage-grade'] }, i.key))) }), g.key))), !isDamagesEmpty && damageGroups.length > 0 && (_jsx("div", Object.assign({ className: styles['expand-close'], onClick: handleToggleAll }, { children: _jsx("button", Object.assign({ className: styles['expand-close-link'], color: "gray" }, { children: isAllOpen ? (_jsx(Ditto, { componentId: "closeall" })) : (_jsx(Ditto, { componentId: "expandall" })) })) }))), evenness && (_jsxs(_Fragment, { children: [!isDamagesEmpty && _jsx(Divider, {}), _jsx(AverageGradingItem, { title: evenness.title, grade: evenness.grade, learnMoreLink: evennessWikiUrl })] }))] })));
}
