var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { createSlice } from '@reduxjs/toolkit';
import { CancelableRequest } from '../../common/cancelableRequest';
import { ITEMS_PER_PAGE } from '../../common/consts/config';
import { ConditionFeatureType, ConditionSelectedFeatureType } from '../../common/consts/panelTab';
import { DEBOUNCE_FETCH_LIST_MS } from '../../common/consts/time';
import { RESPONSE_ERROR_CODE } from '../../common/fetch';
import { rangeToValues } from '../../common/range';
import { getURLSearchParams } from '../../common/url';
import { createAppAsyncThunk } from '../../common/utils/createAppAsyncThunk';
import { createDebouncedAsyncThunk } from '../../common/utils/createDebouncedAsyncThunk';
import { exportPointByIdsList, exportPointList, exportSegmentsByIdsList, exportSegmentsList, getConditionCount, getPointsList, getReconstructionProposals } from '../../services/condition';
import { getSegmentsList } from '../../services/condition/segment';
import { getTimePickerParams } from '../../services/timepicker';
import { addNotificationMessage } from './notification';
import { fetchConditionFilters } from './shared';
export const DEFAULT_SEGMENT_LENGTH = 4;
export const MAX_SEGMENT_LENGTH = 100;
export const DEFAULT_GRADE_MIN = 1;
export const DEFAULT_GRADE_MAX = 5;
export const GRADE_STEP = 0.5;
const initialFilter = {
    reconstructionProposalId: undefined,
    grade: {
        min: DEFAULT_GRADE_MIN,
        max: DEFAULT_GRADE_MAX
    },
    segmentLengthMin: 0,
    segmentLengthMax: 0,
    streetType: [],
    surfaceType: [],
    damageType: []
};
const initialSort = {
    sortBy: 'grade',
    sortOrder: 'desc'
};
const conditionParams = (_a = getURLSearchParams().q) === null || _a === void 0 ? void 0 : _a.condition;
const initialState = {
    points: {
        resultList: [],
        totalCount: 0,
        resultFetchStatus: 'idle',
        isSelectedAll: false
    },
    segments: {
        resultList: [],
        resultFetchStatus: 'idle',
        totalCount: 0,
        totalLengthM: 0,
        isSelectedAll: false
    },
    currentPage: 1,
    streetTypes: [],
    surfaceTypes: [],
    damageTypes: [],
    reconstructionProposals: [],
    useMapBounds: (conditionParams === null || conditionParams === void 0 ? void 0 : conditionParams.useMapBounds) || true,
    activeTabKey: (conditionParams === null || conditionParams === void 0 ? void 0 : conditionParams.activeTabKey) || 'filter',
    selectedFeatureType: (_b = conditionParams === null || conditionParams === void 0 ? void 0 : conditionParams.selectedFeatureType) !== null && _b !== void 0 ? _b : ConditionSelectedFeatureType.both,
    sort: Object.assign(Object.assign({}, initialSort), conditionParams === null || conditionParams === void 0 ? void 0 : conditionParams.sort),
    filter: Object.assign(Object.assign({}, initialFilter), conditionParams === null || conditionParams === void 0 ? void 0 : conditionParams.filter),
    multiSelectedFeatures: [],
    isMultiSelectOn: false
};
function getResultListParams(state) {
    const { locale } = state.app;
    const { bounds } = state.mainMap;
    const { filter, useMapBounds, currentPage, sort: { sortBy, sortOrder } } = state.leftPanelCondition;
    const timepicker = state.presetsPanel.timepicker.selectedOption;
    const mapBoundFilter = useMapBounds ? bounds : undefined;
    const grades = filter.grade.max === DEFAULT_GRADE_MAX && filter.grade.min === DEFAULT_GRADE_MIN
        ? []
        : rangeToValues(filter.grade);
    const damages = {};
    filter.damageType.forEach((damage) => {
        damages[damage.name] = {
            min: damage.range.min,
            max: damage.range.max,
            exclude: damage.isExcluded
        };
    });
    return {
        filter: {
            bbox: mapBoundFilter
                ? {
                    xmin: bounds.west,
                    ymin: bounds.south,
                    xmax: bounds.east,
                    ymax: bounds.north
                }
                : {
                    xmin: -180,
                    ymin: -90,
                    xmax: 180,
                    ymax: 90
                },
            timepicker: getTimePickerParams(timepicker),
            grades,
            damages,
            streetTypes: filter.streetType,
            surfaceTypes: filter.surfaceType,
            segmentLengthMin: filter.segmentLengthMin,
            segmentLengthMax: filter.segmentLengthMax
        },
        limit: ITEMS_PER_PAGE,
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        sortBy,
        sortOrder,
        language: locale
    };
}
export const fetchPointsResultList = createDebouncedAsyncThunk('leftPanel/condition/fetchPointsResultList', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const body = getResultListParams(state);
    return yield CancelableRequest.send(getPointsList, body);
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchSegmentsResultList = createDebouncedAsyncThunk('leftPanel/condition/fetchSegmentsResultList', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const teamAccount = state.auth.teamAccount;
    const body = Object.assign(Object.assign({}, getResultListParams(state)), { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode });
    return yield CancelableRequest.send(getSegmentsList, body);
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchResultListCount = createDebouncedAsyncThunk('leftPanel/condition/fetchResultListCount', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const body = getResultListParams(state);
    return yield CancelableRequest.send(getConditionCount, body);
}), DEBOUNCE_FETCH_LIST_MS);
export const fetchReconstructionProposals = createAppAsyncThunk('leftPanel/condition/fetchReconstructionProposals', (_, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    return yield getReconstructionProposals(state.app.locale);
}));
export const exportFeaturesTk = createAppAsyncThunk('leftPanel/condition/exportFeaturesTk', (type, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { locale } = state.app;
    const { userAccount } = state.auth;
    const { points: { isSelectedAll: isPointsSelectedAll }, segments: { isSelectedAll: isSegmentsSelectedAll }, multiSelectedFeatures } = state.leftPanelCondition;
    if (!(userAccount === null || userAccount === void 0 ? void 0 : userAccount.email))
        return;
    const isSelectedAll = type === ConditionFeatureType.point ? isPointsSelectedAll : isSegmentsSelectedAll;
    let selectedPointIds = [];
    let selectedSegmentIds = [];
    if (type === ConditionFeatureType.point) {
        for (const f of multiSelectedFeatures) {
            if (f.type === ConditionFeatureType.point && f.recordId) {
                selectedPointIds.push(f.recordId);
            }
        }
    }
    else if (type === ConditionFeatureType.segment) {
        for (const f of multiSelectedFeatures) {
            if (f.type === ConditionFeatureType.segment && f.id) {
                selectedSegmentIds.push(f.id);
            }
        }
    }
    let respond;
    if (isSelectedAll || multiSelectedFeatures.length === 0) {
        const params = getResultListParams(state);
        const body = {
            email: userAccount === null || userAccount === void 0 ? void 0 : userAccount.email,
            filter: Object.assign(Object.assign({}, params.filter), { excludePointIds: selectedPointIds, excludeSegmentIds: selectedSegmentIds }),
            language: locale
        };
        if (type === ConditionFeatureType.point) {
            respond = yield exportPointList(body);
        }
        else if (type === ConditionFeatureType.segment) {
            respond = yield exportSegmentsList(body);
        }
    }
    else {
        const body = {
            email: userAccount === null || userAccount === void 0 ? void 0 : userAccount.email,
            ids: type === ConditionFeatureType.point ? selectedPointIds : selectedSegmentIds,
            language: locale
        };
        if (type === ConditionFeatureType.point) {
            respond = yield exportPointByIdsList(body);
        }
        else if (type === ConditionFeatureType.segment) {
            respond = yield exportSegmentsByIdsList(body);
        }
    }
    if ((respond === null || respond === void 0 ? void 0 : respond.errorCode) !== RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({ type: 'error' }));
    }
    if ((respond === null || respond === void 0 ? void 0 : respond.errorCode) === RESPONSE_ERROR_CODE.success) {
        dispatch(addNotificationMessage({
            type: 'success',
            ditto: { title: { componentId: 'toast.processingexport' } }
        }));
    }
    return respond;
}));
export const leftPanelConditionSlice = createSlice({
    name: 'leftPanelCondition',
    initialState,
    reducers: {
        reset: () => initialState,
        resetConditionFilter: (state) => {
            state.filter = initialFilter;
            state.selectedFeatureType = ConditionSelectedFeatureType.both;
        },
        setConditionFilter: (state, action) => {
            state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
        },
        setConditionSorting: (state, action) => {
            state.sort = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setFirstPage: (state) => {
            state.currentPage = 1;
        },
        setConditionActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setSelectedFeatureType: (state, action) => {
            state.selectedFeatureType = action.payload;
        },
        setUseMapBounds: (state, action) => {
            state.useMapBounds = action.payload;
        },
        clearMultiSelectedFeatures: (state) => {
            state.multiSelectedFeatures = [];
        },
        toggleMultiSelectedFeature: (state, action) => {
            if (state.multiSelectedFeatures.find((f) => f.id === action.payload.id)) {
                state.multiSelectedFeatures = state.multiSelectedFeatures.filter((f) => f.id !== action.payload.id);
                return;
            }
            state.multiSelectedFeatures = [action.payload, ...state.multiSelectedFeatures];
        },
        setMultiSelectedFeatures: (state, action) => {
            state.multiSelectedFeatures = action.payload;
        },
        setIsMultiSelectOn: (state, action) => {
            state.isMultiSelectOn = action.payload;
        },
        setIsPointsSelectedAll: (state, action) => {
            state.points.isSelectedAll = action.payload;
        },
        setIsSegmentsSelectedAll: (state, action) => {
            state.segments.isSelectedAll = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPointsResultList.pending, (state) => {
            state.points.resultFetchStatus = 'loading';
        })
            .addCase(fetchPointsResultList.fulfilled, (state, action) => {
            var _a;
            state.points.resultFetchStatus = 'completed';
            if ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.result) {
                state.points.resultList = action.payload.result.list;
            }
        })
            .addCase(fetchResultListCount.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.points.totalCount = action.payload.result.points;
                state.segments.totalCount = action.payload.result.segments;
            }
        })
            .addCase(fetchSegmentsResultList.pending, (state) => {
            state.segments.resultFetchStatus = 'loading';
        })
            .addCase(fetchSegmentsResultList.fulfilled, (state, action) => {
            var _a;
            state.segments.resultFetchStatus = 'completed';
            if ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.result) {
                state.segments.resultList = action.payload.result.list;
            }
        })
            .addCase(fetchReconstructionProposals.fulfilled, (state, action) => {
            if (action.payload.result) {
                state.reconstructionProposals = action.payload.result;
            }
        })
            .addCase(fetchConditionFilters.fulfilled, (state, action) => {
            if (action.payload.result) {
                const { min, max } = action.payload.result.filters.segmentDetails;
                if (min && max) {
                    const minValue = Math.floor(min);
                    const maxValue = Math.ceil(max);
                    if (state.filter.segmentLengthMin === initialFilter.segmentLengthMin &&
                        state.filter.segmentLengthMax === initialFilter.segmentLengthMax) {
                        state.filter.segmentLengthMin = minValue;
                        state.filter.segmentLengthMax = maxValue;
                    }
                    initialFilter.segmentLengthMin = minValue;
                    initialFilter.segmentLengthMax = maxValue;
                }
            }
        });
    }
});
export const { reset: resetLeftPanelCondition, resetConditionFilter, setConditionFilter, setConditionSorting, setUseMapBounds, setConditionActiveTabKey, setSelectedFeatureType, setCurrentPage, setFirstPage, toggleMultiSelectedFeature: toggleConditionMultiSelectedFeature, clearMultiSelectedFeatures: clearConditionMultiSelectedFeatures, setMultiSelectedFeatures: setConditionMultiSelectedFeatures, setIsMultiSelectOn: setConditionIsMultiSelectOn, setIsPointsSelectedAll: setConditionIsPointsSelectedAll, setIsSegmentsSelectedAll: setConditionIsSegmentsSelectedAll } = leftPanelConditionSlice.actions;
export default leftPanelConditionSlice.reducer;
