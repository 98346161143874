var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './PrintImage.module.css';
export function PrintImage(_a) {
    var { caption } = _a, props = __rest(_a, ["caption"]);
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("img", Object.assign({ className: styles['image'] }, props)), _jsx("span", Object.assign({ className: styles['caption'] }, { children: caption }))] })));
}
