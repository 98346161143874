export const MAP_STYLE_ID = 'achimhoth/ckmi7mg3t11li18qv5mpbdy2o';
const MAP_STYLE_ID_SATELLITE = 'mapbox/satellite-streets-v12';
export const MAP_STYLE_URL = `mapbox://styles/${MAP_STYLE_ID}`;
export const MAP_STYLE_URL_SATELLITE = `mapbox://styles/${MAP_STYLE_ID_SATELLITE}`;
export const MAP_VIA_LAT = 48.77024213327074;
export const MAP_VIA_LNG = 9.170047476167392;
export const MINIMAP_DEFAULT_ZOOM = 18;
export const MAP_MARKER_CLICK_ZOOM = 16;
export const MAP_MARKER_CLUSTER_ZOOM = 15;
export const PRINT_MAP_ZOOM = 15;
export const MAP_STATIC_HEIGHT = 230;
export const MAP_STATIC_WIDTH = 400;
export const MAP_DEFAULT_BOUND = {
    north: 9,
    east: 49,
    south: 8.5,
    west: 48
};
export const MAP_MARKER_ICON_SIZE = 24;
export const MAP_MARKER_ICON_VERTICAL_OFFSET = -1 * MAP_MARKER_ICON_SIZE;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MAP_MARKER_ICON_HORIZONTAL_OFFSET = -0.25 * MAP_MARKER_ICON_SIZE;
export const MAP_ASSET_ICON_SIZE = 28;
export const MAP_ASSET_ICON_HORIZONTAL_OFFSET = 0;
export const MAP_ASSET_ICON_VERTICAL_OFFSET = 0;
export const MAP_TRAFFIC_SIGN_ICON_VERTICAL_OFFSET = -42;
export const MAP_TRAFFIC_SIGN_ICON_HORIZONTAL_OFFSET = -18;
export const MAP_NOTE_ICON_VERTICAL_OFFSET = -10;
export const MAP_NOTE_ICON_HORIZONTAL_OFFSET = 10;
export const MAP_SNAPSHOT_QUALITY = 1;
export const NON_SELECTABLE_FEATURES = ['referencing-flag', 'note'];
export const SECONDARY_LAYERS = ['referencing-flag', 'note'];
// NOTE: follow -> follow mouse, fixed -> stay at feature location
export const MAP_POPOVER_BEHAVIOR = {
    note: 'fixed'
};
export const MAP_POPOVER_ANCHOR = {
    note: 'center'
};
export const MAP_RIGHT_CLICK_ACTION_CURSOR = {
    'note-creator-picker': 'crosshair'
};
export const MAP_RIGHT_CLICK_ACTION_ANCHOR = {
    none: 'top-left',
    'note-creator': 'center'
};
export const MAP_CURSOR = {
    default: 'auto',
    crosshair: 'crosshair'
};
// Popovers in top half should drop down instead of up
export const MAP_VERTICAL_POPOVER_SPLIT = 1 / 2;
