var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { Map, useMap } from 'react-map-gl';
import { bbox } from '@turf/turf';
import { format, parse } from 'date-fns';
import { Ditto } from 'ditto-react';
import { getFilterLabel, getFilterLabels } from '../../common/conditionFilterTypes';
import { MAP_SNAPSHOT_QUALITY, MAP_STYLE_URL } from '../../common/consts/map';
import { PLAN_DATE_VARIANT_ID, PLAN_PROPERTY_ID } from '../../common/consts/plan';
import { PRINT_WINDOW_DELAY } from '../../common/consts/time';
import { getPlanEfficiencyIcon, getPlanEfficiencyName, getPlanPriorityIcon, getPlanPriorityLabel, getPlanPriorityName, getPlanThirdPartyIcon, getPlanThirdPartyLabel, getSignIcon, getSignTranslation } from '../../common/convert';
import { getGroupFromSignTree, getSignFromSignTree, getSignTypeIcon } from '../../common/convert/signInventory';
import { PLANS_SOURCE_ID, plansOutlineLayer, POINTS_SOURCE_ID, pointsLayer, SEGMENTS_SOURCE_ID, segmentsLayer, SIGN_INVENTORY_SOURCE_ID, signInventoryLayer, TRAFFIC_SIGNS_SOURCE_ID, trafficSignsLayer } from '../../common/mapbox';
import { getDateFnsLocale } from '../../common/utils/locale';
import { getCompletionYearMonthString, getDatePickerDateFormat, getTranslatedMonthByCorrectIndex } from '../../common/utils/time';
import { getUnitTypeById } from '../../common/utils/units';
import { DamageGradingPanel } from '../../components/DamageGradingPanel';
import { DetailsClassification } from '../../components/DetailsPanel/DetailsClassification/DetailsClassification';
import { DetailsClassificationItem } from '../../components/DetailsPanel/DetailsClassification/DetailsMetaInformationItem/DetailsClassificationItem';
import { TreatmentSelectItemCurrentSelectedValue } from '../../components/EditorForms/TreatmentSelect/TreatmentSelectField/TreatmentSelectField';
import { FormInputCurrentSelectedValue } from '../../components/FormInputSelect/FormInputSelect';
import { IconResizer } from '../../components/IconResizer/IconResizer';
import { ImageCrop } from '../../components/ImageCrop/ImageCrop';
import { ImageWithTag } from '../../components/ImageWithTag/ImageWithTag';
import { PrintPage } from '../../components/PrintPage';
import { PrintHeader } from '../../components/PrintPage/PrintHeader';
import { CategorySelectItemCurrentSelectedValue } from '../../components/SegmentForm/CategorySelectItem/CategorySelectItem';
import { StatusSelectItemCurrentSelectedValue } from '../../components/SegmentForm/StatusSelectItem/StatusSelectItem';
import { TextSuffix } from '../../components/TextSuffix/TextSuffix';
import config from '../../config/config.json';
import { useDateTimeFormatter } from '../../hooks/formatting/useDateTimeFormatter';
import { useFormatter } from '../../hooks/formatting/useFormatter';
import { useUnitFormatter } from '../../hooks/formatting/useUnitFormatter';
import { useLayerChecker } from '../../hooks/useLayerChecker';
import { useMapInit } from '../../hooks/useMapInit';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { fetchTableViewSettings } from '../../state/slices/leftPanelPlan';
import { fetchFilterTypes, fetchPlanCategory, fetchPlanStatus, fetchPlanTreatmentsList, fetchPlanUnitTypes } from '../../state/slices/shared';
import { Divider } from '../../uikit/Divider/Divider';
import { ErrorPageContainer } from '../ErrorPageContainer/ErrorPageContainer';
import { UnitFormatContainer } from '../Formatters/UnitFormatContainer/UnitFormatContainer';
const IMAGE_SIZE_SIGN_CROP = { height: 194, width: 253 };
export function PrintContainer() {
    const dispatch = useAppDispatch();
    const maps = useMap();
    const { teamAccount } = useAppSelector((s) => s.auth);
    const { isLoading: isPrintDataLoading, data, error } = useAppSelector((s) => s.print);
    const dateTimeFormatter = useDateTimeFormatter();
    const { formatAddress } = useFormatter();
    const unitFormatter = useUnitFormatter();
    const { shouldHideSurfaceTypes } = useLayerChecker();
    const { locale } = useAppSelector((s) => s.app);
    const { table: { settings: tableSettings } } = useAppSelector((state) => state.leftPanelPlan);
    const { planCategoryList, planStatusList, planTreatmentsList, planUnitTypes, conditionFilters: { types: { streetTypes, surfaceTypes, roadDamages, roadDamageGroups } }, signTree } = useAppSelector((state) => state.shared);
    const handleMapInit = useMapInit();
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [mapsLoaded, setMapsLoaded] = useState(0);
    const [isSharedDataLoading, setIsSharedDataLoading] = useState(true);
    const [mapSnapshots, setMapSnapshots] = useState([]);
    const customProperties = useMemo(() => (tableSettings === null || tableSettings === void 0 ? void 0 : tableSettings.filter((column) => !column.isDefault)) || [], [tableSettings]);
    useEffect(() => {
        if (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) {
            let promises = [
                fetchFilterTypes()
            ];
            if (data.type === 'plan') {
                promises = [
                    ...promises,
                    fetchPlanCategory(),
                    fetchPlanTreatmentsList(),
                    fetchPlanStatus(),
                    fetchPlanUnitTypes(),
                    fetchTableViewSettings()
                ];
            }
            Promise.all(promises.map((promise) => dispatch(promise))).then(() => setIsSharedDataLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrintDataLoading, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode]);
    useEffect(() => {
        if (data.results) {
            const totalImagesWithUrl = data.results
                .map((r) => r.photoUrls.filter((p) => !!p).length)
                .reduce((total, photoCount) => total + photoCount, 0);
            if (imagesLoaded === totalImagesWithUrl &&
                mapsLoaded === data.results.length &&
                !isPrintDataLoading &&
                !isSharedDataLoading) {
                setTimeout(() => window.print(), PRINT_WINDOW_DELAY);
            }
        }
    }, [data, imagesLoaded, mapsLoaded, isPrintDataLoading, isSharedDataLoading]);
    function onMapLoad(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const mapId = event.target.getContainer().id || 'default';
            const map = maps[mapId];
            const snapshots = [];
            if (data.results && map) {
                const mapObject = map.getMap();
                let layer, sourceId;
                switch (data.type) {
                    case 'plan':
                        sourceId = PLANS_SOURCE_ID;
                        layer = plansOutlineLayer;
                        break;
                    case 'segment':
                        sourceId = SEGMENTS_SOURCE_ID;
                        layer = segmentsLayer;
                        break;
                    case 'point':
                        sourceId = POINTS_SOURCE_ID;
                        layer = pointsLayer;
                        break;
                    case 'sign':
                        sourceId = TRAFFIC_SIGNS_SOURCE_ID;
                        layer = trafficSignsLayer;
                        break;
                    case 'sign-inventory':
                        sourceId = SIGN_INVENTORY_SOURCE_ID;
                        layer = signInventoryLayer;
                        break;
                }
                if (!layer || !sourceId)
                    return;
                for (const item of data.results) {
                    let geojson = item.geojson;
                    if (data.type === 'plan') {
                        const value = item.feature.value;
                        geojson = value.lineGeom;
                    }
                    mapObject.addSource(sourceId, { type: 'geojson', data: geojson }).addLayer(layer);
                    map.fitBounds(bbox(geojson), {
                        padding: 30,
                        animate: false,
                        maxZoom: 17
                    });
                    // Wait for map to have loaded enough for snapshot
                    yield map.once('idle');
                    const image = map.getCanvas().toDataURL('image/png', MAP_SNAPSHOT_QUALITY);
                    snapshots.push({
                        base64: image,
                        height: 500,
                        width: 500
                    });
                    mapObject.removeLayer(layer.id).removeSource(sourceId);
                    setMapsLoaded((prevMapsLoaded) => prevMapsLoaded + 1);
                }
            }
            setMapSnapshots(snapshots);
        });
    }
    function onImageLoad() {
        setImagesLoaded((prevImgsLoaded) => prevImgsLoaded + 1);
    }
    if (error) {
        return _jsx(ErrorPageContainer, { variant: "generic" });
    }
    if (!data.results || isPrintDataLoading || isSharedDataLoading) {
        return _jsx(PrintPage, { variant: "loading" });
    }
    if (!mapSnapshots.length) {
        return (_jsxs(_Fragment, { children: [_jsx(Map, { id: "map", ref: handleMapInit, mapStyle: MAP_STYLE_URL, mapboxAccessToken: config.mapbox.token, onLoad: onMapLoad, preserveDrawingBuffer: true, style: {
                        width: 500,
                        height: 500,
                        opacity: 0,
                        position: 'absolute',
                        pointerEvents: 'none'
                    } }), _jsx(PrintPage, { variant: "loading", loadingValue: mapsLoaded / data.results.length })] }));
    }
    switch (data.type) {
        case 'segment': {
            return (_jsx(_Fragment, { children: data.results.map((segment, index) => {
                    var _a;
                    const { feature, photoUrls } = segment;
                    const props = {
                        variant: 'condition',
                        header: (_jsx(PrintHeader, { type: "segment", dateTime: dateTimeFormatter(feature.value.createdAt), title: feature.value.displayName, id: feature.value.streetId.toString(), details: _jsxs(DetailsClassification, Object.assign({ style: "no-underline" }, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.start" }), content: formatAddress(feature.value.addressStart, {
                                            isShort: true
                                        }) }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.end" }), content: formatAddress(feature.value.addressEnd, {
                                            isShort: true
                                        }) }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "streetclassification.classification" }), content: getFilterLabel(streetTypes, feature.value.streetType) }), !shouldHideSurfaceTypes && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.surfacetype" }), content: feature.value.surfaceTypes })), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.length" }), content: _jsx(UnitFormatContainer, { type: "length", value: feature.value.drivenStreetDistance }) })] })) })),
                        map: mapSnapshots[index] && (_jsx("img", { src: mapSnapshots[index].base64, width: mapSnapshots[index].width, height: mapSnapshots[index].height })),
                        photo: photoUrls[0] && _jsx("img", { src: photoUrls[0], onLoad: onImageLoad }),
                        sidebar: (_jsx(DamageGradingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, overallGrade: (_a = feature.value.grade) === null || _a === void 0 ? void 0 : _a.emi, damageStats: feature.value.damageStats, roadDamageTypes: roadDamages, roadDamageGroups: roadDamageGroups, showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser, variant: "print" }))
                    };
                    return _jsx(PrintPage, Object.assign({}, props), feature.value.streetId);
                }) }));
        }
        case 'point': {
            return (_jsx(_Fragment, { children: data.results.map((point, index) => {
                    var _a;
                    const { feature, photoUrls } = point;
                    const props = {
                        variant: 'condition',
                        header: (_jsx(PrintHeader, { type: "point", dateTime: dateTimeFormatter(feature.value.timeStamp), title: feature.value.displayName, id: feature.value.pointId.toString(), details: _jsxs(DetailsClassification, Object.assign({ style: "no-underline" }, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "streetclassification.classification" }), content: getFilterLabel(streetTypes, feature.value.streetType) }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.surfacetype" }), content: feature.value.surfaceType })] })) })),
                        map: mapSnapshots[index] && (_jsx("img", { src: mapSnapshots[index].base64, width: mapSnapshots[index].width, height: mapSnapshots[index].height })),
                        photo: photoUrls[0] && _jsx("img", { src: photoUrls[0], onLoad: onImageLoad }),
                        sidebar: (_jsx(DamageGradingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, overallGrade: (_a = feature.value.grade) === null || _a === void 0 ? void 0 : _a.emi, damageStats: feature.value.damageStats, roadDamageTypes: roadDamages, roadDamageGroups: roadDamageGroups, showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser, variant: "print" }))
                    };
                    return _jsx(PrintPage, Object.assign({}, props), feature.value.pointId);
                }) }));
        }
        case 'sign': {
            return (_jsx(_Fragment, { children: data.results.map((sign, index) => {
                    const { feature, photoUrls } = sign;
                    const props = {
                        variant: 'sign',
                        header: (_jsx(PrintHeader, { type: "sign", dateTime: dateTimeFormatter(feature.value.timestamp), title: feature.value.displayName, id: feature.value.id.toString(), details: _jsxs(_Fragment, { children: [_jsxs(DetailsClassification, Object.assign({ style: "no-underline" }, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "latitude" }), content: feature.value.offsetPosition[1] }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "longitude" }), content: feature.value.offsetPosition[0] })] })), _jsx(DetailsClassification, Object.assign({ style: "no-underline" }, { children: _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "objects.status" }), content: _jsxs(_Fragment, { children: [getSignIcon(feature.value.damageType), getSignTranslation(feature.value.damageType)] }) }) }))] }) })),
                        map: mapSnapshots[index] && (_jsx("img", { src: mapSnapshots[index].base64, width: mapSnapshots[index].width, height: mapSnapshots[index].height })),
                        photo: photoUrls[0] && (_jsx(ImageWithTag, { tag: feature.value.tag, src: photoUrls[0], tagLabel: _jsxs(_Fragment, { children: [_jsx(IconResizer, Object.assign({ size: 12 }, { children: getSignIcon(feature.value.damageType) })), getSignTranslation(feature.value.damageType)] }), onLoad: onImageLoad })),
                        sidebar: (_jsx(ImageCrop, { tag: feature.value.tag, imageSize: IMAGE_SIZE_SIGN_CROP, src: photoUrls[0], alt: "Traffic Sign" }))
                    };
                    return feature && _jsx(PrintPage, Object.assign({}, props), feature.value.pointUuid);
                }) }));
        }
        case 'sign-inventory': {
            return (_jsx(_Fragment, { children: data.results.map((signInfo, index) => {
                    const { feature, photoUrls } = signInfo;
                    const sign = getSignFromSignTree(signTree, feature.value.tag.subSpecifiedClass);
                    const group = getGroupFromSignTree(signTree, feature.value.tag.subSpecifiedClass);
                    const damageType = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'objects.status' }), content: _jsxs(_Fragment, { children: [getSignIcon(feature.value.damageType), getSignTranslation(feature.value.damageType)] }) }));
                    const properties = feature.value.properties.map((property, index) => {
                        return (_jsx(DetailsClassificationItem, { headline: property.name, content: property.textValue ||
                                property.numberValue ||
                                property.dateValue ||
                                property.selectValue || _jsx(Ditto, { componentId: "notspecified" }) }, index));
                    });
                    const documents = feature.value.folders.map((folder, index) => {
                        const content = folder.files.length > 0 ? (folder.files.map((file) => {
                            return file.name;
                        })) : (_jsx(_Fragment, { children: "-" }));
                        return (_jsx(DetailsClassificationItem, { headline: folder.componentId ? (_jsx(Ditto, { componentId: folder.componentId })) : (folder.name), content: content }, index));
                    });
                    const signName = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'signs.details.name' }), content: _jsxs(_Fragment, { children: [getSignTypeIcon(feature.value.tag.subSpecifiedClass), sign === null || sign === void 0 ? void 0 : sign.abbreviation] }) }));
                    const signGroup = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'signs.details.group' }), content: group === null || group === void 0 ? void 0 : group.name }));
                    const bastId = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'signs.details.bastid' }), content: sign === null || sign === void 0 ? void 0 : sign.bastId }));
                    const orientation = (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: 'trafficsigns.orientation' }), content: feature.value.orientation }));
                    const props = {
                        variant: 'sign-inventory',
                        header: (_jsx(PrintHeader, { type: "sign-inventory", dateTime: dateTimeFormatter(feature.value.timestamp), title: feature.value.displayName, id: feature.value.id.toString(), details: _jsxs(_Fragment, { children: [_jsxs(DetailsClassification, Object.assign({ style: "no-underline" }, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "latitude" }), content: feature.value.offsetPosition[1] }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "longitude" }), content: feature.value.offsetPosition[0] })] })), _jsxs(DetailsClassification, Object.assign({ style: "no-underline" }, { children: [signGroup, signName] })), _jsxs(DetailsClassification, { children: [bastId, damageType, config.enableOrientation && orientation] }), _jsx(DetailsClassification, Object.assign({ headline: _jsx(Ditto, { componentId: 'properties' }) }, { children: properties })), _jsx(DetailsClassification, Object.assign({ style: "no-underline", headline: _jsx(Ditto, { componentId: 'files.files' }) }, { children: documents }))] }) })),
                        map: mapSnapshots[index] && (_jsx("img", { src: mapSnapshots[index].base64, width: mapSnapshots[index].width, height: mapSnapshots[index].height })),
                        photo: photoUrls[0] && (_jsx(ImageWithTag, { tag: feature.value.tag, src: photoUrls[0], tagLabel: _jsxs(_Fragment, { children: [_jsx(IconResizer, Object.assign({ size: 12 }, { children: getSignIcon(feature.value.damageType) })), getSignTranslation(feature.value.damageType)] }), onLoad: onImageLoad })),
                        sidebar: (_jsx(ImageCrop, { tag: feature.value.tag, imageSize: IMAGE_SIZE_SIGN_CROP, src: photoUrls[0], alt: "Traffic Sign" }))
                    };
                    return feature && _jsx(PrintPage, Object.assign({}, props), feature.value.pointUuid);
                }) }));
        }
        case 'plan': {
            return (_jsx(_Fragment, { children: data.results.map((plan, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
                    const { feature } = plan;
                    const planCategory = planCategoryList.find((category) => { var _a; return ((_a = feature.value) === null || _a === void 0 ? void 0 : _a.categoryId) === category.id; });
                    const planStatus = planStatusList.find((status) => { var _a; return ((_a = feature.value) === null || _a === void 0 ? void 0 : _a.statusId) === status.id; });
                    const planTreatment = planTreatmentsList.find((treatment) => { var _a; return ((_a = feature.value) === null || _a === void 0 ? void 0 : _a.treatmentId) === treatment.id; });
                    const props = {
                        variant: 'plan',
                        header: (_jsx(PrintHeader, { type: "plan", dateTime: dateTimeFormatter(feature.value.createdAt), title: ((_a = feature.value) === null || _a === void 0 ? void 0 : _a.name) || '', id: ((_b = feature.value.planId) === null || _b === void 0 ? void 0 : _b.toString()) || '', details: _jsxs(_Fragment, { children: [((_c = feature.value) === null || _c === void 0 ? void 0 : _c.note) || '', _jsxs(DetailsClassification, { children: [planCategory && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.budget.type" }), content: _jsx(CategorySelectItemCurrentSelectedValue, { category: {
                                                        categoryId: planCategory.id,
                                                        categoryName: planCategory.name
                                                    } }) })), planStatus && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.status" }), content: _jsx(StatusSelectItemCurrentSelectedValue, { status: {
                                                        statusId: planStatus.id,
                                                        statusName: planStatus.name
                                                    } }) })), planTreatment && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.measure" }), content: _jsx(TreatmentSelectItemCurrentSelectedValue, { title: planTreatment.title, categoryTypeId: planTreatment.categoryTypeId }) })), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.firmlyplanned" }), content: _jsx(FormInputCurrentSelectedValue, { text: getPlanThirdPartyLabel(feature.value.thirdParty), icon: getPlanThirdPartyIcon(feature.value.thirdParty) }) }), ((_d = feature.value) === null || _d === void 0 ? void 0 : _d.priority) !== undefined && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.priority" }), content: _jsx(FormInputCurrentSelectedValue, { icon: getPlanPriorityIcon(getPlanPriorityName((_e = feature.value) === null || _e === void 0 ? void 0 : _e.priority)), text: getPlanPriorityLabel(getPlanPriorityName((_f = feature.value) === null || _f === void 0 ? void 0 : _f.priority)) }) })), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.area" }), content: _jsx(UnitFormatContainer, { type: "area", value: ((_h = (_g = feature.value) === null || _g === void 0 ? void 0 : _g.width) !== null && _h !== void 0 ? _h : 0) *
                                                        ((_k = (_j = feature.value) === null || _j === void 0 ? void 0 : _j.length) !== null && _k !== void 0 ? _k : 0) }) }), ((_l = feature.value) === null || _l === void 0 ? void 0 : _l.completionYear) !== undefined && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.plannedfor" }), content: (_m = feature.value) === null || _m === void 0 ? void 0 : _m.completionYear })), ((_o = feature.value) === null || _o === void 0 ? void 0 : _o.completionMonth) !== undefined && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.month" }), content: getTranslatedMonthByCorrectIndex(locale, (_p = feature.value) === null || _p === void 0 ? void 0 : _p.completionMonth) })), ((_q = feature.value) === null || _q === void 0 ? void 0 : _q.costs) !== undefined && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.price" }), content: _jsx(UnitFormatContainer, { type: "currency", value: (_r = feature.value) === null || _r === void 0 ? void 0 : _r.costs }) })), ((_s = feature.value) === null || _s === void 0 ? void 0 : _s.yearsOfUse) !== undefined && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.year" }), content: (_t = feature.value) === null || _t === void 0 ? void 0 : _t.yearsOfUse })), ((_u = feature.value) === null || _u === void 0 ? void 0 : _u.factor) !== undefined && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "plannedsegments.efficiency" }), content: _jsxs(_Fragment, { children: [getPlanEfficiencyIcon(getPlanEfficiencyName((_v = feature.value) === null || _v === void 0 ? void 0 : _v.factor)), (_w = feature.value) === null || _w === void 0 ? void 0 : _w.factor] }) }))] })] }) })),
                        map: mapSnapshots[index] && (_jsx("img", { src: mapSnapshots[index].base64, width: mapSnapshots[index].width, height: mapSnapshots[index].height })),
                        sidebar: (_jsxs(_Fragment, { children: [_jsxs(DetailsClassification, { children: [_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.start" }), content: formatAddress((_x = feature.value) === null || _x === void 0 ? void 0 : _x.addressStart, {
                                                isShort: true
                                            }) }), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "segment.end" }), content: formatAddress((_y = feature.value) === null || _y === void 0 ? void 0 : _y.addressEnd, {
                                                isShort: true
                                            }) }), feature.value.classificationTypes &&
                                            feature.value.classificationTypes.length > 0 && (_jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "streetclassification.classification" }), content: getFilterLabel(streetTypes, feature.value.classificationTypes[0].toString()) })), _jsx(DetailsClassificationItem, { headline: _jsx(Ditto, { componentId: "filter.surfacetype" }), content: getFilterLabels(surfaceTypes, feature.value.surfaceTypes) })] }), _jsx(DamageGradingPanel, { countryCode: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode, overallGrade: feature.value.grade, damageStats: feature.value.damageStats, roadDamageTypes: roadDamages, roadDamageGroups: roadDamageGroups, showGradePCI: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePci, showGradePaser: teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.settings.enablePaser, variant: "print" }), customProperties.length > 0 &&
                                    feature.value.customData &&
                                    Object.keys(feature.value.customData).length > 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx("div", Object.assign({ style: {
                                                margin: '24px 12px 8px',
                                                fontWeight: 600,
                                                fontSize: 16
                                            } }, { children: _jsx(Ditto, { componentId: "properties" }) })), _jsx(DetailsClassification, { children: customProperties.map((property, index) => {
                                                var _a, _b, _c;
                                                const value = feature.value.customData
                                                    ? feature.value.customData[property.columnKey]
                                                    : undefined;
                                                const selectedValue = (_b = (_a = property.selectOptions) === null || _a === void 0 ? void 0 : _a.find((option) => option.id === value)) === null || _b === void 0 ? void 0 : _b.title;
                                                if (!value)
                                                    return;
                                                const { unit, formattedValue } = unitFormatter({
                                                    type: property.unitTypeId
                                                        ? getUnitTypeById(property.unitTypeId)
                                                        : undefined,
                                                    factor: 1,
                                                    value,
                                                    unit: (_c = planUnitTypes.find((unit) => unit.id ===
                                                        property.unitTypeId)) === null || _c === void 0 ? void 0 : _c.title
                                                });
                                                let parsedDate;
                                                if (feature.value.customData &&
                                                    feature.value.customData[property.columnKey] &&
                                                    property.propertyTypeId ===
                                                        PLAN_PROPERTY_ID.date) {
                                                    parsedDate = parse(value, 'yyyy-MM-dd', new Date(), {
                                                        locale: getDateFnsLocale(locale, teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode)
                                                    });
                                                }
                                                const datePickerFormat = getDatePickerDateFormat(teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode);
                                                switch (property.propertyTypeId) {
                                                    case PLAN_PROPERTY_ID.text:
                                                        return (_jsx(DetailsClassificationItem, { headline: property.title, content: value }, `custom-property-${index}`));
                                                    case PLAN_PROPERTY_ID.numeric:
                                                        return (_jsx(DetailsClassificationItem, { headline: property.title, content: value !== undefined &&
                                                                property.unitTypeId ? (_jsx(TextSuffix, { text: formattedValue, suffix: ` ${unit}` })) : (formattedValue) }, `custom-property-${index}`));
                                                    case PLAN_PROPERTY_ID.list:
                                                        return (_jsx(DetailsClassificationItem, { headline: property.title, content: selectedValue }, `custom-property-${index}`));
                                                    case PLAN_PROPERTY_ID.date:
                                                        if (!parsedDate)
                                                            return null;
                                                        return (_jsx(DetailsClassificationItem, { headline: property.title, content: property.variantTypeId ===
                                                                PLAN_DATE_VARIANT_ID.month
                                                                ? getCompletionYearMonthString(locale, parsedDate.getFullYear(), parsedDate.getMonth() +
                                                                    1, true)
                                                                : format(parsedDate, datePickerFormat) }, `custom-property-${index}`));
                                                }
                                            }) })] }))] }))
                    };
                    return _jsx(PrintPage, Object.assign({}, props), feature.value.planId);
                }) }));
        }
        default:
            return _jsx(_Fragment, {});
    }
}
