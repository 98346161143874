import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { SegmentPoints, Signs2 } from '../../components/icons';
export const getPrintHeaderIcon = (type) => {
    switch (type) {
        case 'sign':
            return _jsx(Signs2, {});
        default:
            return _jsx(SegmentPoints, {});
    }
};
export const getPrintHeaderLabel = (type) => {
    switch (type) {
        case 'sign':
            return _jsx(Ditto, { componentId: "damagedsign" });
        default:
            // TODO: use ditto translation for other layers
            return type.charAt(0).toUpperCase() + type.slice(1);
    }
};
