// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RL3u78p4rNIZUtxdRmn1{width:205mm;margin:0 auto;display:grid;grid-template-columns:repeat(2, 1fr);grid-column-gap:10px;grid-row-gap:10px;page-break-after:always}.hzzUnrTuX6vnvpqUpJ9H{grid-area:1/1/2/2}.COKvwhnfxXkMZISJMttF>*{max-width:100%;max-height:146mm}.JPZy31OtoCgSmKlGVkVl{width:105mm;height:105mm}.JPZy31OtoCgSmKlGVkVl img{display:block;width:100%;height:100%}.ngCBH_oc192u6si42Okj{padding-top:75px;grid-area:1/2/2/2}.aH0i_VozpBFdRxVTvQ8c{padding-top:214px;grid-area:1/2/2/2}.mxbXDuxxOISNEKUk9VJn{height:100vh;display:flex;justify-content:center;align-items:center;flex-direction:column;gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/PrintPage/PrintPage.module.css"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,aAAA,CACA,YAAA,CACA,oCAAA,CACA,oBAAA,CACA,iBAAA,CACA,uBAAA,CAGJ,sBACI,iBAAA,CAGJ,wBACI,cAAA,CACA,gBAAA,CAGJ,sBACI,WAAA,CACA,YAAA,CAGJ,0BACI,aAAA,CACA,UAAA,CACA,WAAA,CAGJ,sBACI,gBAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".container {\n    width: 205mm; /* A4 width - 2 * page.margin */\n    margin: 0 auto;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-column-gap: 10px;\n    grid-row-gap: 10px;\n    page-break-after: always; /* Always start print pages on new page */\n}\n\n.main {\n    grid-area: 1 / 1 / 2 / 2;\n}\n\n.photo > * {\n    max-width: 100%;\n    max-height: 146mm; /* 0.5 * A4 height - (2 * page.margin) */\n}\n\n.map {\n    width: calc(210mm / 2);\n    height: calc(210mm / 2);\n}\n\n.map img {\n    display: block;\n    width: 100%;\n    height: 100%;\n}\n\n.sidebar {\n    padding-top: 75px;\n    grid-area: 1 / 2 / 2 / 2;\n}\n\n.sidebar-sign {\n    padding-top: 214px;\n    grid-area: 1 / 2 / 2 / 2;\n}\n\n.loader {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RL3u78p4rNIZUtxdRmn1`,
	"main": `hzzUnrTuX6vnvpqUpJ9H`,
	"photo": `COKvwhnfxXkMZISJMttF`,
	"map": `JPZy31OtoCgSmKlGVkVl`,
	"sidebar": `ngCBH_oc192u6si42Okj`,
	"sidebar-sign": `aH0i_VozpBFdRxVTvQ8c`,
	"loader": `mxbXDuxxOISNEKUk9VJn`
};
export default ___CSS_LOADER_EXPORT___;
