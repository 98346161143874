import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getPrintHeaderIcon, getPrintHeaderLabel } from '../../../common/convert/print';
import { DetailsMetaInformation } from '../../DetailsPanel/DetailsMetaInformation/DetailsMetaInformation';
import { DetailsMetaInformationItem } from '../../DetailsPanel/DetailsMetaInformation/DetailsMetaInformationItem/DetailsMetaInformationItem';
import { CalendarBlank, Clock, ID } from '../../icons';
import styles from './PrintHeader.module.css';
const color = '#000000';
export function PrintHeader({ type, dateTime, title, id, details }) {
    return (_jsxs("div", Object.assign({ className: styles['container'] }, { children: [_jsx("div", Object.assign({ className: styles['meta'] }, { children: _jsxs(DetailsMetaInformation, { children: [_jsx(DetailsMetaInformationItem, { icon: getPrintHeaderIcon(type), label: getPrintHeaderLabel(type), color: color }), _jsx(DetailsMetaInformationItem, { icon: _jsx(CalendarBlank, {}), label: dateTime.date, color: color }), _jsx(DetailsMetaInformationItem, { icon: _jsx(Clock, {}), label: dateTime.time, color: color }), _jsx(DetailsMetaInformationItem, { icon: _jsx(ID, {}), label: id, color: color })] }) })), _jsx("p", Object.assign({ className: styles['title'] }, { children: title })), _jsx("div", Object.assign({ className: styles['details'] }, { children: details }))] })));
}
