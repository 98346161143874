// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s5F2yk9ln051uhTyYOqw{display:flex;flex-direction:column}.lqQwASidq3hqYtcrt994{margin-left:6px}.TwlJq7g5OfS5xF6sB0gl{font-weight:700;font-size:16px;line-height:20px;color:var(--primary-black);margin-left:10px;width:fit-content;box-shadow:0 -10px 0 #fbd400 inset}`, "",{"version":3,"sources":["webpack://./src/components/PrintPage/PrintHeader/PrintHeader.module.css"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CAGJ,sBACI,eAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,0BAAA,CACA,gBAAA,CACA,iBAAA,CACA,kCAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.meta {\n    margin-left: 6px;\n}\n\n.title {\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 20px;\n    color: var(--primary-black);\n    margin-left: 10px;\n    width: fit-content;\n    box-shadow: 0 -10px 0 #fbd400 inset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `s5F2yk9ln051uhTyYOqw`,
	"meta": `lqQwASidq3hqYtcrt994`,
	"title": `TwlJq7g5OfS5xF6sB0gl`
};
export default ___CSS_LOADER_EXPORT___;
