import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { classes } from 'classifizer';
import { Ditto } from 'ditto-react';
import { GradeBadge } from '../../../GradeBadge/GradeBadge';
import { IconResizer } from '../../../IconResizer/IconResizer';
import { ArrowRight, CaretCloseSmall, CaretOpenSmall, External } from '../../../icons';
import { InfoTooltip } from '../../../InfoTooltip/InfoTooltip';
import styles from './AverageGradingItem.module.css';
export function AverageGradingItem({ title, grade, gradeFrom, learnMoreLink, children, isOpen, onToggle, variant = 'default' }) {
    return (_jsxs("div", Object.assign({ className: classes(styles['container'], styles[variant], children && styles['container--hover']) }, { children: [_jsxs("div", Object.assign({ className: classes(styles['damage-group']), onClick: onToggle }, { children: [_jsxs("div", Object.assign({ className: classes(styles['title']) }, { children: [title, learnMoreLink && (_jsx("div", Object.assign({ className: styles['tooltip'] }, { children: _jsx(InfoTooltip, { tooltip: _jsxs(_Fragment, { children: [_jsx(IconResizer, Object.assign({ size: 18 }, { children: _jsx(External, {}) })), _jsx("span", Object.assign({ className: styles['link'] }, { children: _jsx(Ditto, { componentId: "infobutton.learnmore" }) }))] }), onClick: () => window.open(learnMoreLink, '_blank') }) })))] })), _jsxs("div", Object.assign({ className: classes(styles['grade']) }, { children: [gradeFrom && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classes(styles['spacer']) }, { children: _jsx(GradeBadge, { grade: gradeFrom }) })), _jsx("div", Object.assign({ className: classes(styles['spacer']) }, { children: _jsx(ArrowRight, {}) }))] })), _jsx("div", Object.assign({ className: classes(styles['spacer']) }, { children: _jsx(GradeBadge, { grade: grade }) }))] })), children && (_jsx("div", Object.assign({ className: classes(styles['spacer']) }, { children: _jsx("div", Object.assign({ className: classes(styles['toggle-icon']) }, { children: _jsx(IconResizer, Object.assign({ size: 18 }, { children: isOpen ? _jsx(CaretOpenSmall, {}) : _jsx(CaretCloseSmall, {}) })) })) })))] })), isOpen && _jsx("div", Object.assign({ className: classes(styles['damage-grades']) }, { children: children }))] })));
}
