import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Ditto } from 'ditto-react';
import { toggleItemInArray } from '../../../common/utils/array';
import { useDittoWrapper } from '../../../hooks/useDittoWrapper';
import { Button } from '../../../uikit/Button/Button';
import { Divider } from '../../../uikit/Divider/Divider';
import Link from '../../../uikit/Link/Link';
import { FilterSelectbox } from '../../FilterSelectbox/FilterSelectbox';
import { Close } from '../../icons';
import { Modal } from '../../Modal/Modal';
import { Search } from '../../Search/Search';
import styles from './MultiselectModal.module.css';
/**
 * Designed to be shared for multiple layers, for example task attributes, asset attributes and so on.
 */
export function MultiselectModal({ options, initialValues, onClose, onDone, onCustomize, title }) {
    const search = useDittoWrapper({ componentId: 'search' });
    const [localValues, setLocalValues] = useState(initialValues);
    const [searchValue, setSearchValue] = useState('');
    function handleSelectboxClick(id) {
        setLocalValues(toggleItemInArray(localValues, id));
    }
    function sortOptions(a, b) {
        return (a.name.toLowerCase().indexOf(searchValue.toLowerCase()) -
            b.name.toLowerCase().indexOf(searchValue.toLowerCase()));
    }
    const filterdOptions = searchValue
        ? options
            .filter((o) => o.name.toLowerCase().includes(searchValue.toLowerCase()))
            .sort(sortOptions)
        : options;
    return (_jsx(Modal, { width: 480, title: title, trailing: _jsx(Button, { variant: "square", color: "gray", icon: _jsx(Close, {}), onClick: onClose }), content: _jsxs("div", Object.assign({ className: styles['modal-search'] }, { children: [_jsx(Search, { placeholder: search, value: searchValue, onChange: (value) => setSearchValue(value), variant: "field" }), _jsx("div", Object.assign({ className: styles['modal-search-results'] }, { children: filterdOptions.length === 0 ? (_jsx("p", Object.assign({ className: styles['no-results'] }, { children: _jsx(Ditto, { componentId: "search.noresults" }) }))) : (_jsx(_Fragment, { children: filterdOptions.map((o) => (_jsx("div", Object.assign({ className: styles['search-result'] }, { children: _jsx(FilterSelectbox, { content: o.name, isChecked: localValues.includes(o.id), onClick: () => handleSelectboxClick(o.id), color: "black", height: 40 }) }), o.id))) })) }))] })), footer: _jsxs("div", Object.assign({ className: styles['footer'] }, { children: [_jsx(Divider, { margin: "16px", marginTop: "0" }), _jsxs("div", Object.assign({ className: styles['buttons'] }, { children: [_jsx(Link, Object.assign({ variant: "button", onClick: onCustomize }, { children: _jsx(Ditto, { componentId: "assets.customizeattributes" }) })), _jsx("div", Object.assign({ className: styles['button-reset'] }, { children: _jsx(Button, Object.assign({ color: "gray", variant: 'fullwidth', onClick: () => setLocalValues(initialValues) }, { children: _jsx(Ditto, { componentId: "appliedfilter.reset" }) })) })), _jsx("div", Object.assign({ className: styles['button-confirm'] }, { children: _jsx(Button, Object.assign({ color: "dark", variant: 'fullwidth', onClick: () => onDone(localValues) }, { children: _jsx(Ditto, { componentId: "done" }) })) }))] }))] })), onClickOutside: onClose }));
}
