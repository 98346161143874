import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Ditto } from 'ditto-react';
import { POPUP_SETTINGS_FIELDNAME } from '../../common/settings';
import { IntroModal } from '../../components/Modals/IntroModal/IntroModal';
import { getDittoComponentId, useDittoWrapper } from '../../hooks/useDittoWrapper';
import { useModalContext } from '../../state/context';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { updateUserPopupSettingsTk } from '../../state/slices/auth';
import Link from '../../uikit/Link/Link';
export function RemainingServiceLifeIntroModalContainer() {
    const { removeModal } = useModalContext();
    const dispatch = useAppDispatch();
    const { teamAccount } = useAppSelector((state) => state.auth);
    const videourl = useDittoWrapper({
        componentId: 'plannedsegments.budget.featurereleasemodal.videourl'
    });
    const wikiurl1 = useDittoWrapper({ componentId: 'wikilink.budgettool' });
    const wikiurl2 = useDittoWrapper({ componentId: 'wikilink.remainingservicelife' });
    function closeModal(dontShowAgain) {
        if (dontShowAgain) {
            dispatch(updateUserPopupSettingsTk(POPUP_SETTINGS_FIELDNAME.doNotShowRemainingServiceLifeIntroModalAgain));
        }
        removeModal('RemainingServiceLifeIntroModal');
    }
    function handleReadMoreClick(url) {
        window.open(url, '_blank');
    }
    return (_jsx(IntroModal, { onNoClick: closeModal, onYesClick: closeModal, title: _jsx(Ditto, { componentId: "plannedsegments.budget.featurereleasemodal.headline" }), videoUrl: videourl, content: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ style: { marginBottom: 12 } }, { children: [_jsx(Ditto, { componentId: "plannedsegments.budget.featurereleasemodal.budgetplanner", richText: true }), _jsx(Link, Object.assign({ onClick: () => handleReadMoreClick(wikiurl1) }, { children: _jsx(Ditto, { componentId: "read-more" }) }))] })), _jsx(Ditto, { componentId: getDittoComponentId({
                        componentId: 'plannedsegments.budget.featurereleasemodal.rsl',
                        useUsVariant: (teamAccount === null || teamAccount === void 0 ? void 0 : teamAccount.countryCode) === 'US'
                    }), richText: true }), _jsx(Link, Object.assign({ onClick: () => handleReadMoreClick(wikiurl2) }, { children: _jsx(Ditto, { componentId: "read-more" }) }))] }), yesLabel: _jsx(Ditto, { componentId: "newfeaturemodal.explore" }) }));
}
